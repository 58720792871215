import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export default function BassinFace() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "bassin_vide.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Img
      fluid={data.file.childImageSharp.fluid}
      alt="La piscine de Jean Dame"
      className="shadow-lg rounded-lg my-8"
    />
  );
}
