import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/content.js";
import SEO from '../components/seo';
import Important from 'src/components/design-system/important';
import BassinVide from 'src/components/images/bassin-vide';
import Link from 'src/components/design-system/link';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="L'activité" description="Comment se déroule l'activité de bébés nageurs avec L'enfant d'eau" mdxType="SEO" />
    <h2>{`L'activité`}</h2>
    <h3>{`Informations pratiques`}</h3>
    <p>{`L'activité se déroule au Centre Sportif Jean Dame (17 rue Léopold Bellan – 75002 Paris). Elle a lieu tous les samedis. Pendant les vacances scolaires, l’activité est assurée en fonction des possibilités qui nous sont offertes d’accéder au bassin.`}</p>
    <p>{`Les enfants sont repartis en créneaux par tranche d'âge. L'âge minimum est de 5 mois révolu au début du premier cours.`}</p>
    <p>{`L’assurance est garantie par l’adhésion à la Fédération Léo Lagrange et couvre l’enfant et deux
accompagnateurs adultes (non nominatifs). Un adulte (deux au maximum) doit accompagner l’enfant
dans l’eau. Les mineurs non-inscrits (frère, sœur, cousin(e)…) ne sont pas autorisés à rentrer dans
l’eau.`}</p>
    <p>{`Nous exigeons, pour les assurances, que soient présentés pour chaque enfant :`}</p>
    <ul>
      <li parentName="ul">{`Lors de la première inscription, un certificat de vaccination attestant des vaccinations obligatoires selon le calendrier vaccinal français établi par la Direction Générale de la Santé (voir le carnet de santé). La présentation des pages du carnet de santé concernant la vaccination (avec copie envoyée par email à l’association) peut tenir lieu de certificat.`}</li>
      <li parentName="ul">{`Pour chaque inscription, un certificat médical attestant de l’absence de contre-indication à la pratique d'une activité aquatique, datant de moins de 6 mois au moment de la demande d’inscription. Conformément au décret no 2021-564 du 7 mai 2021, ce certificat médical peut être remplacé par une `}<Link href="/attestation-parentale.pdf" newTab mdxType="Link">{`attestation sur l’honneur`}</Link>{` d’une personne exerçant l’autorité parentale sur l'enfant que chacune des rubriques du `}<Link href="/questionnaire-sante.pdf" newTab mdxType="Link">{`questionnaire de santé`}</Link>{` donne lieu à une réponse négative.`}</li>
    </ul>
    <Important mdxType="Important">
Aucun enfant ne peut aller dans l’eau s’il n’est pas couvert par l’assurance de l’association, c’est-à-dire si ces certificats n’ont pas été remis à l’association.
    </Important>
    <h3>{`Règlement`}</h3>
    <p>{`Toutes les règles sont écrites dans le `}<Link href="/reglementinterieurv2.3.pdf" newTab mdxType="Link">{`règlement intérieur`}</Link>{`. La règle la plus importante est :`}</p>
    <Important mdxType="Important">
Chaque enfant dans la piscine ou au bord du bassin est sous la responsabilité de ses parents. Il doit y avoir un parent pour chaque enfant présent.
    </Important>
    <p>{`L’activité se déroule dans le bassin école du centre Jean Dame que l’association loue à la Ville de
Paris. Les règles et les directives de la Ville de Paris s’imposent à l’association.`}</p>
    <p>{`Les règles d’hygiène obligatoires dans les piscines sont notamment :`}</p>
    <ul>
      <li parentName="ul">{`la douche savonnée (parents et enfants de plus d’un an) avant d’aller dans l’eau`}</li>
      <li parentName="ul">{`le port du bonnet de bains est obligatoire pour tous,`}</li>
      <li parentName="ul">{`les caleçons de bain sont interdits,`}</li>
      <li parentName="ul">{`le passage par le pédiluve est obligatoire,`}</li>
      <li parentName="ul">{`l’interdiction des chaussures sur le bord du bassin,`}</li>
      <li parentName="ul">{`l’interdiction de manger au bord du bassin,`}</li>
      <li parentName="ul">{`l’interdiction d’accéder au bassin en cas de maladie infectieuse ou d’affection de la peau
contagieuse.`}</li>
    </ul>
    <p>{`De plus, nous vous signalons qu’au-delà de la protection des autres enfants,
certaines affections (otite chronique, tympan ouvert, infections cutanées, conjonctivite
infectieuse, …) peuvent être aggravées par la pratique d’une activité aquatique.`}</p>
    <p>{`Les règles de sécurité sont notamment :`}</p>
    <ul>
      <li parentName="ul">{`l’interdiction d’utiliser des récipients en verre, par exemple biberons et petits pots pour bébés,`}</li>
      <li parentName="ul">{`l’interdiction de courir sur le bord du bassin`}</li>
      <li parentName="ul">{`l’interdiction de sauter et de plonger dans le bassin`}</li>
      <li parentName="ul">{`l’interdiction de pousser un enfant ou un adulte dans le bassin.`}</li>
    </ul>
    <BassinVide mdxType="BassinVide" />
    <h3>{`Guides pour les parents`}</h3>
    <p>{`Les informations de cette page sont tirées du `}<Link href="/reglementinterieurv2.3.pdf" newTab mdxType="Link">{`règlement intérieur`}</Link>{` et du `}<Link href="/guide-parents-juillet-2020.pdf" newTab mdxType="Link">{`guide des parents`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      